.register-button {
  background-color: rgb(49, 90, 163);
  color: white;
  padding: 10px 50px;
  border-radius: 8px;
  text-decoration: none;
  font-weight: bold;
  font-size: 50px;
  border: rgb(49, 90, 163) 5px solid;
  display: inline-block;
  margin-bottom: 60px;
  transition: all 0.3s ease;
  text-align: center;
}

.register-button:hover {
  background-color: rgb(39, 75, 140);
  color: white;
  border: white 5px solid;
  border-radius: 10px;
  text-decoration: none;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.6);
}

@media screen and (max-width: 768px) {
  .register-button {
    font-size: 28px;
    padding: 20px 30px;
  }
}

@media screen and (max-width: 480px) {
  .register-button {
    font-size: 22px;
    padding: 15px 25px;
  }
}
